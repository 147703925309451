<template>
  <PlanPlugin></PlanPlugin>
  <div class="d-flex align-items-center m-3" style="height: 100vh">
    <div class="bg-white m-auto col-12 col-md-8 border" style="border-radius: 10px">
      <div class="p-4">
        <div class="d-flex align-items-center">
          <div class="col"></div>
          <h4 v-if="stepNumber < 4" class="text-center col-10 m-0 fw-bold">Welcome to Professional FAQs app!</h4>
          <div v-if="stepNumber < 4" class="col text-end">
            <div class="bg-hover-color p-2 rounded d-flex justify-content-center align-items-center border" style="cursor: pointer;" @click="skipTutorial">Skip
              <i class="fa-solid fa-forward ms-1 mt-1"></i>
            </div>
          </div>
        </div>
        <div class="mt-4" v-if="stepNumber === 1">
          <h5>STEP 1 : Create first Category</h5>
          <div class="mt-4">
            <div class="mt-3">
              <label for="category-name" class="fw-bold mb-2">Category name: <span class="" style="color: red">*</span></label>
              <input v-model="categoryTitle" id="category-name" type="text" class="form-control" >
            </div>
            <div class="mt-3">
              <label for="category-description" class="fw-bold mb-2">Description:</label>
              <input v-model="categoryDescription" id="category-description" type="text" class="form-control">
            </div>
            <div class="mt-2">
              <span class="text-danger" style="font-size: .8rem">* This is requied</span>
            </div>
          </div>
        </div>
        <div class="mt-4" v-if="stepNumber === 2">
          <h5>STEP 2 : Create first FAQ</h5>
          <div class="product-scroll" style="max-height: 500px; overflow-y: auto">
            <div class="mt-3">
              <label for="question" class="fw-bold mb-2">Question: <span class="" style="color: red">*</span></label>
              <input v-model="faqQuestion" id="question" type="text" class="form-control" >
            </div>
            <div class="mt-3">
              <div class="form-group mt-3">
                <label class="pb-2 fw-bold" for="description">Answer <span class="text-danger">*</span></label>
                <editor
                  name="content"
                  id="content"
                  v-model="faqAnswer"
                  api-key="iw2savyfxm3l9qi0l30klxs2ne4dugzr451uxhs7ook0gc3p"
                  :init="{
                    forced_root_block : false,
                    height: 230,
                    menubar: insert,
                    plugins: [
                      'advlist autolink lists link image charmap print preview anchor',
                      'searchreplace visualblocks code fullscreen',
                      'insertdatetime media table paste code help wordcount'
                    ],
                    toolbar:
                      'undo redo | formatselect | bold italic backcolor | \
                      alignleft aligncenter alignright alignjustify | \
                      bullist numlist outdent indent | removeformat | help'
                  }"
                />
              </div>
            </div>
            <div class="mt-3">
              <label for="answer" class="fw-bold mb-2">Category: </label>
              <select disabled class="form-select" aria-label="Default select example" v-model="categoryTitle">
                <option selected :value="categoryTitle">{{ categoryTitle }}</option>
              </select>
            </div>
            <div class="mt-2">
              <span class="text-danger" style="font-size: .8rem">* This is requied</span>
            </div>
          </div>
        </div>
        <div class="mt-4" v-if="(stepNumber === 3 || stepNumber === 4) && !finalTutorial">
          <h5>STEP 3 : Choose Template</h5>
          <div class="product-scroll mt-4" style="height: 500px; overflow-y: auto">
            <div class="d-flex col fw-bold text-center">
              <div class="col-6">
                <div class="me-2" @click="chooseTemplate(1)">
                  <div class="p-2 rounded mb-2" :style="templateNumber === 1? 'border: 3px solid #ff8200' : 'border: 2px solid #ccc'">
                    <img class="mb-2" src="./../../../public/template1.png" style="width: 100%;" alt="">
                    <span>Template One</span>
                  </div>
                </div>
              </div>
              <div class="col-6">
                <div class="me-2" @click="chooseTemplate(2)">
                  <div class="p-2 rounded mb-2" :style="templateNumber === 2? 'border: 3px solid #ff8200' : 'border: 2px solid #ccc'">
                    <img class="mb-2" src="./../../../public/template2.png" style="width: 100%;" alt="">
                    <span>Template Two</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="d-flex col fw-bold text-center">
              <div class="col-6">
                <div class="me-2" @click="chooseTemplate(3)" >
                  <div class="p-2 rounded mb-2" :style="templateNumber === 3? 'border: 3px solid #ff8200' : 'border: 2px solid #ccc'">
                    <img class="mb-2" src="./../../../public/template3.png" style="width: 100%;" alt="">
                    <span>Template Three</span>
                  </div>
                </div>
              </div>
              <!-- <div v-else class="col-6" style="position:relative ;" data-bs-toggle="modal" data-bs-target="#subscription">
                <div class="me-2">
                  <div class="p-2 rounded mb-2" :style="templateNumber === 3? 'border: 3px solid #ff8200' : 'border: 2px solid #ccc'">
                    <div class="p-2" style="position:absolute; top: 50%;left: 50%; transform:translate(-50%, -60%); z-index: 1; width: 90%; height: 40%; display: flex; justify-content: center; align-items: center;">
                      <span class="fs-4">
                        <span style="color: #ff8200; cursor:pointer">ONLY FOR PRO PLAN
                        </span>
                      </span>
                    </div>
                    <img class="mb-2" src="./../../../public/template3.png" style="width: 100%; cursor:pointer; opacity: .4;" alt="">
                    <span>Template Three</span>
                  </div>
                </div>
              </div> -->
              <div class="col-6" v-if="fullFeature">
                <div class="me-2" @click="chooseTemplate(4)">
                  <div class="p-2 rounded mb-2" :style="templateNumber === 4? 'border: 3px solid #ff8200' : 'border: 2px solid #ccc'">
                    <img class="mb-2" src="./../../../public/Template04.png" style="width: 100%;" alt="">
                    <span>Template Four</span>
                  </div>
                </div>
              </div>
              <div v-else  data-bs-toggle="modal" data-bs-target="#subscription" class="col-6" style="position:relative ;">
                <div class="me-2">
                  <div class="p-2 rounded mb-2" :style="templateNumber === 4? 'border: 3px solid #ff8200' : 'border: 2px solid #ccc'">
                    <div class="p-2" style="position:absolute; top: 50%;left: 50%; transform:translate(-50%, -60%); z-index: 1; width: 90%; height: 40%; display: flex; justify-content: center; align-items: center;">
                      <span class="fs-4">
                        <span style="color: #ff8200; cursor:pointer">ONLY FOR PRO PLAN
                        </span>
                      </span>
                    </div>
                    <img class="mb-2" src="./../../../public/Template04.png" style="width: 100%; cursor:pointer; opacity: .4;" alt="">
                    <span>Template Four</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="d-flex col fw-bold text-center">
              <div class="col-6" v-if="fullFeature">
                <div class="me-2" @click="chooseTemplate(5)">
                  <div class="p-2 rounded mb-2" :style="templateNumber === 5? 'border: 3px solid #ff8200' : 'border: 2px solid #ccc'">
                    <img class="mb-2" src="./../../../public/Template05.png" style="width: 100%;" alt="">
                    <span>Template Five</span>
                  </div>
                </div>
              </div>
              <div v-else  data-bs-toggle="modal" data-bs-target="#subscription" class="col-6" style="position:relative ;">
                <div class="me-2">
                  <div class="p-2 rounded mb-2" :style="templateNumber === 5? 'border: 3px solid #ff8200' : 'border: 2px solid #ccc'">
                    <div class="p-2" style="position:absolute; top: 50%;left: 50%; transform:translate(-50%, -60%); z-index: 1; width: 90%; height: 40%; display: flex; justify-content: center; align-items: center;">
                      <span class="fs-4">
                        <span style="color: #ff8200; cursor:pointer">ONLY FOR PRO PLAN
                        </span>
                      </span>
                    </div>
                    <img class="mb-2" src="./../../../public/Template05.png" style="width: 100%; cursor:pointer; opacity: .4;" alt="">
                    <span>Template Five</span>
                  </div>
                </div>
              </div>
              <div class="col-6" v-if="fullFeature">
                <div class="me-2" @click="chooseTemplate(6)">
                  <div class="p-2 rounded mb-2" :style="templateNumber === 6? 'border: 3px solid #ff8200' : 'border: 2px solid #ccc'">
                    <img class="mb-2" src="./../../../public/Template06.png" style="width: 100%;" alt="">
                    <span>Template Six</span>
                  </div>
                </div>
              </div>
              <div v-else data-bs-toggle="modal" data-bs-target="#subscription" class="col-6" style="position:relative ;">
                <div class="me-2">
                  <div class="p-2 rounded mb-2" :style="templateNumber === 6? 'border: 3px solid #ff8200' : 'border: 2px solid #ccc'">
                    <div class="p-2" style="position:absolute; top: 50%;left: 50%; transform:translate(-50%, -60%); z-index: 1; width: 90%; height: 40%; display: flex; justify-content: center; align-items: center;">
                      <span class="fs-4">
                        <span style="color: #ff8200; cursor:pointer">ONLY FOR PRO PLAN
                        </span>
                      </span>
                    </div>
                    <img class="mb-2" src="./../../../public/Template06.png" style="width: 100%; cursor:pointer; opacity: .4;" alt="">
                    <span>Template Six</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="d-flex col fw-bold text-center">
              <div class="col-6" v-if="fullFeature">
                <div class="me-2" @click="chooseTemplate(7)">
                  <div class="p-2 rounded mb-2" :style="templateNumber === 7? 'border: 3px solid #ff8200' : 'border: 2px solid #ccc'">
                    <img class="mb-2" src="./../../../public/Template07.png" style="width: 100%;" alt="">
                    <span>Template Seven</span>
                  </div>
                </div>
              </div>
              <div v-else data-bs-toggle="modal" data-bs-target="#subscription" class="col-6" style="position:relative ;">
                <div class="me-2">
                  <div class="p-2 rounded mb-2" :style="templateNumber === 7? 'border: 3px solid #ff8200' : 'border: 2px solid #ccc'">
                    <div class="p-2" style="position:absolute; top: 50%;left: 50%; transform:translate(-50%, -60%); z-index: 1; width: 90%; height: 40%; display: flex; justify-content: center; align-items: center;">
                      <span class="fs-4">
                        <span style="color: #ff8200; cursor:pointer">ONLY FOR PRO PLAN
                        </span>
                      </span>
                    </div>
                    <img class="mb-2" src="./../../../public/Template07.png" style="width: 100%; cursor:pointer; opacity: .4;" alt="">
                    <span>Template Seven</span>
                  </div>
                </div>
              </div>
              <div class="col-6" v-if="fullFeature">
                <div class="me-2" @click="chooseTemplate(8)">
                  <div class="p-2 rounded mb-2" :style="templateNumber === 8? 'border: 3px solid #ff8200' : 'border: 2px solid #ccc'">
                    <img class="mb-2" src="./../../../public/Template08.png" style="width: 100%;" alt="">
                    <span>Template Eight</span>
                  </div>
                </div>
              </div>
              <div v-else data-bs-toggle="modal" data-bs-target="#subscription" class="col-6" style="position:relative ;">
                <div class="me-2">
                  <div class="p-2 rounded mb-2" :style="templateNumber === 8? 'border: 3px solid #ff8200' : 'border: 2px solid #ccc'">
                    <div class="p-2" style="position:absolute; top: 50%;left: 50%; transform:translate(-50%, -60%); z-index: 1; width: 90%; height: 40%; display: flex; justify-content: center; align-items: center;">
                      <span class="fs-4">
                        <span style="color: #ff8200; cursor:pointer">ONLY FOR PRO PLAN
                        </span>
                      </span>
                    </div>
                    <img class="mb-2" src="./../../../public/Template08.png" style="width: 100%; cursor:pointer; opacity: .4;" alt="">
                    <span>Template Eight</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="" v-if="stepNumber === 4 && finalTutorial">
          <div class="d-flex flex-column flex-lg-row bg-light border overflow-hidden" style="border-radius: 10px">
            <!-- <div class="col-12 col-lg-6">
              <img class="w-100 h-100" style="object-fit: cover; object-position: center center" src="./../../../public/FAQ.jpg" alt="">
            </div> -->
            <div class="col-12 d-block p-4 text-start text-dark">
              <div class="d-flex flex-column justify-content-between h-100">
                <div>
                  <h4 class="pb-2 fw-bold text-center">Welcome to Professional FAQs app!</h4>
                  <p class="mt-4">First of all, thanks a lot for giving me an opportunity to serve you. I will keep this simple & short! Merchants primarily use app for:</p>
                </div>
                <ul>
                  <li class="mb-2">Create elegant FAQ page with most frequently asked questions by customers in various languages.</li>
                  <li class="mb-2">Embed FAQs on other parts of store like product, home, custom pages...</li>
                  <li class="mb-2">Custom CSS allows you to turn your imagination into a reality.</li>
                  <li>Provide a smart, instant and helpful FAQs popup for customers find popular questions.</li>
                </ul>
                <div class="d-flex flex-column mt-3">
                  <button :disabled="disableComplete" @click="completeTutorial" class="btn bgr-primary btn-primary-hover text-white m-auto">
                    <span class="fs-6">Let's get started.</span>
                  </button>
                  <div class="pt-4">
                    <div class="text-center fw-bold">
                      View FAQ Page : 
                      <a target="_blank" style="color: #ff8200" :href="`https://${shopDomain}${faqPageUrl}`">{{ shopDomain }}{{ faqPageUrl }}
                        <i class="fa-solid fa-arrow-up-right-from-square ms-1"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="stepNumber <= 4 && !finalTutorial" class="d-flex align-items-center mt-4" :style="stepNumber === 1? 'justify-content: end' : 'justify-content: space-between'">
          <button class="btn bgr-primary btn-primary-hover text-white" v-if="stepNumber > 1" @click="changeStep('back')">
            <i class="fa-solid fa-arrow-left-long me-1"></i>
            <span class="fs-6">Back</span>
          </button>
          <button style="width: 80px" class="btn bgr-primary btn-primary-hover text-white fs-6" @click="changeStep('next')">
            <div v-if="disableComplete" class="loader loader--style3 ps-1">
              <svg version="1.1" id="loader-1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                width="22px" height="22px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                <path fill="#fff" d="M43.935,25.145c0-10.318-8.364-18.683-18.683-18.683c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615c8.072,0,14.615,6.543,14.615,14.615H43.935z">
                  <animateTransform 
                  attributeType="xml"
                  attributeName="transform"
                  type="rotate"
                  from="0 25 25"
                  to="360 25 25"
                  dur="0.6s"
                  repeatCount="indefinite"/>
                </path>
              </svg>
            </div> 
            <div v-else>
              <span class="fs-6">Next</span>
              <i class="fa-solid fa-arrow-right-long ms-1"></i>
            </div>
          </button>
        </div>      
      </div>
    </div>  
  </div>
</template>

<script>
  import FaqDataService from "../../services/FaqDataService";
  import CategoryDataService from "../../services/CategoryDataService";
  import router from './../../router/index';
  import { ref } from "vue";
  import store from "../../store/index";
  import Editor from '@tinymce/tinymce-vue'
  import TutorialDataService from './../../services/TutorialDataService'
  import { inject } from 'vue'
  import swal from 'sweetalert';
  import PlanPlugin from '../plan/PlanPopup.vue'
  import { templates } from './../../store/modules/templates'
  import TemplateSettingDataService from "./../../services/TemplateSettingDataService";

  export default {
    components: {
      'editor': Editor,
      PlanPlugin: PlanPlugin,
    },
    computed: {
      faqPageUrl () {
        return store.state.data.settings['faq_page_url']
      }
    },
    setup(){
      const toast = inject('$toast');
      const tutoriaActive = ref(true)
      const templateNumber = ref(2)
      const shopDomain = ref()
      shopDomain.value = store.state.auth.shopifyDomain
      const settings = ref()
      settings.value = store.state.data.settings
      const stepNumber = ref(1)
      const categoryTitle = ref()
      const categoryDescription = ref()
      const categoryVisible = ref(true)
      const faqQuestion = ref()
      const faqAnswer = ref()
      const faqVisible = ref(true)
      const allFaqs = ref([])
      const categoriesShow = ref([])
      const categories = ref([])
      const faqs_bu = ref([])
      const locales = ref(store.state.auth.languages)
      const categories_bu = ref([])
      const allCategory = ref([])
      const localeDefault = ref('default')
      const disableComplete = ref(false)
      const finalTutorial = ref(false)
      const fullFeature = ref(store.state.plan.full_feature)
      const templateSetting = ref()
      const template_data = ref([])
      template_data.value = store.state.data.template_setting
      const changeStep = async (action) => {
        if(action == 'next'){
          if((categoryTitle.value === '' || categoryTitle.value === undefined) && stepNumber.value === 1){
            toast.open({
              message: "Category name is requied !",
              type: "default",
              duration: 3000,
              dismissible: true
            })
            return
          }
          if(((faqQuestion.value === '' || faqQuestion.value === undefined) || (faqAnswer.value === '' || faqAnswer.value === undefined)) && stepNumber.value === 2){
            toast.open({
              message: "Question & Answer is requied !",
              type: "default",
              duration: 3000,
              dismissible: true
            })
            return
          }
          if(stepNumber.value < 4){
            stepNumber.value += 1
          }
          else{
            return
          }
          if(stepNumber.value === 4){
            finalTutorial.value = false
            tutoriaActive.value = false
            disableComplete.value = true
            let categoryCreate = {
              title: categoryTitle.value? categoryTitle.value : '',
              description: categoryDescription.value? categoryDescription.value : '',
              is_visible: true,
              feature_category: false,
              locale: 'default'
            }
            let faqCreate = {
              title: faqQuestion.value,
              content: faqAnswer.value,
              is_visible: true,
              feature_faq: false,
              locale: 'default'
            }
            let settings = {
              faq_template_number : templateNumber.value,
              tutorial_active: tutoriaActive.value
            }
            let data = {
              faq: faqCreate,
              category: categoryCreate,
              setting: settings
            }
            await TutorialDataService.create(data)
            .then(async () => {
              await retrieveAllCategory()
              await retrieveFaq()
              await updateTemplateSetting()
              setFaqLocale()
              setCategoryLocale()
              store.state.data.settings.tutorial_active = false
              store.dispatch('data/setSettingsData', store.state.data.settings)
              disableComplete.value = false
              finalTutorial.value = true
            })
            .catch(e => {
              console.log(e)
            })
          }
        }
        else{
          if(stepNumber.value > 1){
            stepNumber.value -= 1
          }
          else{
            return
          }
        }
      }
      const retrieveAllCategory = async function() {
        await CategoryDataService.getAllCategory()
        .then(async response => {
          store.dispatch('data/setAllCategoriesData', response.data)
        })
        .catch(e => {
          console.log(e);
        });
        retrieveCategory()
      };


      const updateTemplateSetting = async() => {
        await TemplateSettingDataService.update(template_data.value.id, templateSetting.value)
      }

      const retrieveCategory = () => {
        store.dispatch('data/setCategoriesData', store.state.data.all_categories.filter(item => {
          return item.locale === 'default'
        }));
      }

      const chooseTemplate = (template_number) => {
        templateNumber.value = template_number
        switch(template_number) {
          case 1 : 
            templateSetting.value = templates.template_1
            break;
          case 2 : 
            templateSetting.value = templates.template_2
            break;
          case 3 : 
            templateSetting.value = templates.template_3
            break;
          case 4 : 
            templateSetting.value = templates.template_4
            break;
          case 5 : 
            templateSetting.value = templates.template_5
            break;
          case 6 : 
            templateSetting.value = templates.template_6
            break;
          case 7 : 
            templateSetting.value = templates.template_7
            break;
          case 8 : 
            templateSetting.value = templates.template_8
            break;
        }
        store.state.data.settings.faq_template_number = template_number
        store.dispatch('data/setSettingsData', store.state.data.settings)
        store.dispatch('data/setTemplateData', templateSetting.value)
      }
      const completeTutorial = () => {
        router.push({
          path: "/storeFAQs",
        })
      }

      const retrieveFaq = async function() {
        await FaqDataService.getAll(localeDefault.value)
        .then(response => {
          faqs_bu.value = response.data;
          if(categories.value.length > 0){
            for(let i = 0; i < categories.value.length; i++){
              const countFaqs = ref()
              const countFeatureFaq = ref()
              countFaqs.value = faqs_bu.value.filter(item => {
                return item.category_identify === categories.value[i].identify
              }).length
              countFeatureFaq.value = faqs_bu.value.filter(item => {
                return (item.category_identify === categories.value[i].identify && item.feature_faq === true)
              }).length
              categories.value[i].count_faqs = countFaqs.value
              categories.value[i].count_feature_faq = countFeatureFaq.value
            }
          }
        })
        .catch(e => {
          console.log(e)
        });
      };

      const setFaqLocale = function(){
        const listLocale = ref([])
        const localesInfo = ref([])
        if(faqs_bu.value.length > 0){
          for(let i = 0; i < faqs_bu.value.length; i++){
            listLocale.value = []
            allFaqs.value.forEach(element => {
              if(faqs_bu.value[i].identify === element.identify && faqs_bu.value[i].category_identify === element.category_identify){
                if(!listLocale.value.some(item => { return item === element.locale })){
                  listLocale.value.push(element.locale)
                }
              }
            })
            localesInfo.value = []
            locales.value = JSON.parse(store.state.auth.languages)
            locales.value.forEach(element => {
              if(listLocale.value.some(item => { return element.locale === item })){
                localesInfo.value.push({
                  locale: element.locale,
                  added: true,
                  language: element.language
                })
              }
              else{
                localesInfo.value.push({
                  locale: element.locale,
                  added: false,
                  language: element.language
                })
              }
            })
            faqs_bu.value[i].locale_info = localesInfo.value
          }
        }
        store.dispatch('data/setFaqsData', faqs_bu.value);
      }

      const setCategoryLocale = function(){
        categories_bu.value = store.state.data.categories
        allCategory.value = store.state.data.all_categories
        const listLocale = ref([])
        const localesInfo = ref([])
        if(categories_bu.value){
          for(let i = 0; i < categories_bu.value.length; i++){
            listLocale.value = []
            allCategory.value.forEach(element => {
              if(categories_bu.value[i].identify === element.identify){
                if(!listLocale.value.some(item => { return item === element.locale })){
                  listLocale.value.push(element.locale)
                }
              }
            })
            const countFaqs = ref()
            countFaqs.value = faqs_bu.value.filter(item => {
              return item.category_identify === categories_bu.value[i].identify
            }).length
            
            localesInfo.value = []
            locales.value.forEach(element => {
              if(listLocale.value.some(item => { return element.locale === item })){
                localesInfo.value.push({
                  locale: element.locale,
                  added: true,
                  language: element.language
                })
              }
              else{
                localesInfo.value.push({
                  locale: element.locale,
                  added: false,
                  language: element.language
                })
              }
            })
            categories_bu.value[i].locale_info = localesInfo.value
            categories_bu.value[i].count_faqs = countFaqs.value
            categories.value = categories_bu.value
          }
          categoriesShow.value = categories_bu.value
          store.dispatch('data/setAllCategoriesData', categoriesShow.value)
        }
      }

      const skipTutorial = async () => {
        await swal({ title: "Warning!",
          text: "Do you really want to skip this ?",
          dangerMode: true,
          buttons: true,    
          type: "success"}).then(async done => {
          if (done) {
            await TutorialDataService.update()
            .then(async () => {
              router.push({
                path: "/storeFAQs",
              })
              store.state.data.settings.tutorial_active = false
              store.dispatch('data/setSettingsData', store.state.data.settings)
            })
            .catch(e => {
              console.log(e)
            })
          }
        });
      }
      return{
        fullFeature,
        finalTutorial,
        skipTutorial,
        retrieveAllCategory,
        retrieveFaq,
        setFaqLocale,
        setCategoryLocale,
        tutoriaActive,
        chooseTemplate,
        templateNumber,
        completeTutorial,
        disableComplete,
        faqVisible,
        faqQuestion,
        faqAnswer,
        categoryDescription,
        categoryVisible,
        categoryTitle,
        changeStep,
        stepNumber,
        shopDomain,
      }
    }
  }
</script>
<style>
h4{
  color: #ff8200
}
.bg-hover-color:hover{
  background-color: #ff8200;
  color: #fff;
}
</style>